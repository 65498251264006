import { graphql, Link } from 'gatsby';
import * as React from 'react';
import Photo from '../components/photo';
import Seo from '../components/seo';
import { useMediaQuery } from '@react-hook/media-query';

import '../global.scss';
import PageDefaultLayout from '../layouts/page-default';
import * as styles from './index.module.scss';

const IndexPage = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext;

  const maxPage = numPages;

  const prevPage =
    currentPage - 1 === 1 ? '/' : '/' + (currentPage - 1).toString();
  const nextPage = '/' + (currentPage + 1).toString();

  let rowLengths = { 0: 0, 1: 0, 2: 0 };
  let imageRows = { 0: [], 1: [], 2: [] };
  let singleRow = [];
  const smallScreen = useMediaQuery('(max-width: 700px)');

  data.photos.nodes.forEach((photo, i) => {
    let selectedRow = 0;

    if (rowLengths[1] < rowLengths[0]) {
      if (rowLengths[2] < rowLengths[1]) {
        selectedRow = 2;
      } else {
        selectedRow = 1;
      }
    } else {
      if (rowLengths[2] < rowLengths[0]) {
        selectedRow = 2;
      }
    }

    let width = 400;
    let widthRatio = width / photo.width;
    let height = photo.height * widthRatio;

    imageRows[selectedRow].push(
      <Photo
        link={photo.links.html}
        key={photo.id}
        blur={photo.blur_hash}
        width={width}
        height={height}
        src={photo.urls.raw}
        alt={photo.alt_description}
      />
    );
    rowLengths[selectedRow] += height;

    singleRow.push(
      <Photo
        link={photo.links.html}
        key={photo.id}
        blur={photo.blur_hash}
        width={width}
        height={height}
        src={photo.urls.raw}
        alt={photo.alt_description}
      />
    );
  });

  return (
    <PageDefaultLayout>
      <Seo></Seo>
      {currentPage === 1 && (
        <div className={styles.profileInfo}>
          <div className={styles.profileDetails}>
            <span className={styles.preHeader}>Portfolio</span>
            <span className={styles.userName}>
              {data.user.user.name ?? data.user.user.username}
            </span>

            {data.user.user.bio ? (
              <div className={styles.bio}>{data.user.user.bio}</div>
            ) : null}
          </div>
          <div className={styles.profilePic}>
            <img
              src={data.user.user.profile_image.large}
              alt={data.user.user.name ?? data.user.user.username}
            />
          </div>
        </div>
      )}

      {currentPage > 1 && (
        <div className={styles.pageController}>
          {currentPage > 1 ? (
            <Link to={prevPage}>&lt;</Link>
          ) : (
            <div className={styles.btnPlaceholder}></div>
          )}
          <span>
            {currentPage}/{maxPage}
          </span>
          {currentPage < maxPage ? (
            <Link to={nextPage}>&gt;</Link>
          ) : (
            <div className={styles.btnPlaceholder}></div>
          )}
        </div>
      )}

      <div className={styles.gallery + (smallScreen ? ' ' + styles.small : '')}>
        {!smallScreen && [
          <div className={styles.row}>{imageRows[0]}</div>,
          <div className={styles.row}>{imageRows[1]}</div>,
          <div className={styles.row}>{imageRows[2]}</div>,
        ]}
        {smallScreen && <div className={styles.row}>{singleRow}</div>}
      </div>

      <div className={styles.pageController}>
        {currentPage > 1 ? (
          <Link to={prevPage}>&lt;</Link>
        ) : (
          <div className={styles.btnPlaceholder}></div>
        )}
        <span>
          {currentPage}/{maxPage}
        </span>
        {currentPage < maxPage ? (
          <Link to={nextPage}>&gt;</Link>
        ) : (
          <div className={styles.btnPlaceholder}></div>
        )}
      </div>

      {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
    </PageDefaultLayout>
  );
};

export const query = graphql`
  query IndexQuery($skip: Int!, $limit: Int!) {
    user: unfolioUnsplashPhoto {
      user {
        username
        name
        profile_image {
          large
        }
        twitter_username
        instagram_username
        location
        bio
      }
    }
    photos: allUnfolioUnsplashPhoto(
      sort: { fields: created_at, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        links {
          html
        }
        urls {
          raw
        }
        alt_description
        blur_hash
        width
        height
        id
      }
    }
  }
`;

export default IndexPage;
