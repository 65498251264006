import * as React from 'react';
import { BlurhashCanvas } from 'react-blurhash';
import PropTypes from 'prop-types';

import * as styles from './photo.module.scss';
import { useInView } from 'react-hook-inview';

const Photo = ({ blur, width, height, src, alt, link }) => {
  const [ref, inView] = useInView({
    rootMargin: '500px',
    threshold: 0,
  });

  return (
    <figure className={styles.photo} ref={ref}>
      <div>
        <a href={link} target="_blank" rel="noreferrer" title={alt}>
          <BlurhashCanvas hash={blur}></BlurhashCanvas>
          <div className={styles.imageContainer}>
            {inView ? (
              <img src={src + `&w=${width}&h=${height}`} alt={alt}></img>
            ) : null}
            <div
              className={styles.ratioKeeper}
              style={{ paddingBottom: `${(height / width) * 100}%` }}
            ></div>
          </div>
        </a>
      </div>
    </figure>
  );
};

Photo.propTypes = {
  blur: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  link: PropTypes.string,
};

export default Photo;
