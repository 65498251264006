// extracted by mini-css-extract-plugin
export var profileInfo = "index-module--profileInfo--3CZHR";
export var profilePic = "index-module--profilePic--2QOw3";
export var profileDetails = "index-module--profileDetails--Q8FZ8";
export var preHeader = "index-module--preHeader--1CmZh";
export var userName = "index-module--userName--2cqGn";
export var bio = "index-module--bio--UH34s";
export var gallery = "index-module--gallery--2DfYO";
export var small = "index-module--small--Cs_AR";
export var row = "index-module--row--1Bcjw";
export var pageController = "index-module--pageController--16Grw";
export var btnPlaceholder = "index-module--btnPlaceholder--X4CLE";